import React from 'react'
import Image from '../beans/Image'
import { Grid, Container, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import header from "../../contents/_data/header.yml"

const useStyles = makeStyles((theme) => ({
  header: {
    flexGrow: 1,
    paddingTop: 20,
    height: 70,
    background: "#fff",
    position: "fixed",
    width: "100%",
    borderBottom: "solid 1px rgb(38, 133, 187)",
    boxShadow: "0 2px 24px 0 rgb(38 133 187 / 25%)",
    zIndex: 1
  }
}));

function Nav() {
  return (
    <Hidden only={["xs", "sm"]}>
      <nav>
          <ul>
          {header.navigation.map((link, id) => (
            <li key={id}><a href={link.url}>{link.label}</a></li>
          ))}
          </ul>
      </nav>
    </Hidden>
  );
}

export default function Header() {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4} md={3}><a href="/" title="Accueil"><Image src={header.logo} alt={header.alt}/></a></Grid>
          <Grid item md={9}><Nav/></Grid>
        </Grid>
      </Container>
    </header>
  )
}
