import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        if(n.node.relativePath.includes(props.src ? (props.src + "").substring(5) : null))
          return n.node;
        return null;
      });
      if (!image) { 
        var str = props.src+' => ';
        data.images.edges.find(n => {
          str += n.node.relativePath + " , ";
        });
        return (<span>{str}</span>); }
      
      var img = getImage(image.node);
      //const imageSizes = image.node.childImageSharp.fluid;
      /*return (
        <img alt={props.alt} src={props.src} data-tmp={img} />
      );*/
      return (
        <GatsbyImage alt={props.alt} title={props.alt} image={img}/>
      );
    }}
  />
)
export default Image