import React from "react"
import Helmet from "react-helmet"
import {Container, Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Nav from "./Nav"
import Header from "./Header"
import Footer from "./Footer"
import CookieConsent from "react-cookie-consent"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
    marginBottom: 20,
    textAlign: 'center',
    backgroundColor: '#2685bb'
  }
}));

export default function Layout(props) {
  const { children, title, description, type, image } = props;
  const classes = useStyles();
  return (
    <div id="outer">
      <Helmet>
        <title>{title} | JCE Châteaubriant</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary" />
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="twitter:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="twitter:image" content={image} />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2685bb" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#2685bb" />
      </Helmet>
      <CookieConsent enableDeclineButton overlay={true} sameSite={"strict"}
        buttonStyle={{background:'#2685bb',fontWeight:'bold',color:'white'}}
        declineButtonStyle={{background:'#d09c3f',fontWeight:'bold',color:'black'}}
        location="bottom"
        buttonText="Tout accepter"
        declineButtonText="Tout refuser"
        cookieName="gatsby-gdpr-google-tagmanager">
        Notre site utilise des cookies afin de réunir les données nécessaires à l'amélioration de votre expérience de navigation et à la personnalisation des contenus en fonction de vos intérêts.
      </CookieConsent>
      <Nav/>
      <main id="page">
        <Header/>
        <section className={classes.root}>
          <Container>
            <Typography variant="h1" gutterBottom>{title}</Typography>
          </Container>
        </section>
        {children}
        <Footer/>
      </main>
    </div>
  )
}
