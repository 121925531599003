import React from 'react'
import { Hidden } from '@material-ui/core'
import { scaleRotate as Menu } from 'react-burger-menu'
import Image from '../beans/Image'
import header from '../../contents/_data/header.yml'

export default function Nav() {
  return (
    <Hidden mdUp>
      <Menu right disableAutoFocus pageWrapId={"page"} outerContainerId={"outer"}>
          <h2><Image src={"/contents/images/jci.png"} alt={"Menu"}/></h2>
          {header.navigation.map((link, id) => (
            <a key={id} href={link.url}>{link.label}</a>
          ))}
      </Menu>
    </Hidden>
  );
}
