import React from 'react'
import { Grid, Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Facebook, LinkedIn, Twitter, Instagram, YouTube, MailOutline, Link } from '@material-ui/icons'
import footer from "../../contents/_data/footer.yml"
import ReactMarkdown from 'react-markdown'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 30,
    marginTop: 30, 
    backgroundColor: '#2685bb'
  },
  topline: {
    borderTop: 'solid 1px #fff'
  },
  small: {
    fontSize: '0.75em'
  },
  aside: {
    textAlign: 'center'
  },
  social: {
    float: 'right',
    marginLeft: 20
  }
}));

function Copyright() {
  const classes = useStyles();
  return (
    <aside className={classes.small}>
      <ReactMarkdown source={footer.copyright}/>
    </aside>
  );
}

function getSocialIcon(network) {
  switch(network) {
    case 'facebook': return <Facebook/>;
    case 'twitter': return <Twitter/>; 
    case 'instagram': return <Instagram/>; 
    case 'linkedin': return <LinkedIn/>; 
    case 'youtube': return <YouTube/>; 
    default:
  }
}

function SocialNetworks() {
  const classes = useStyles();
  return (
    <aside>
      {footer.social.map((social, id) => (
        <Grid key={id} item xs={12} className={classes.social}>
          <a href={social.url} alt={social.network} title={social.network} target="_blank" rel="noreferrer">{getSocialIcon(social.network)}</a><br/>
        </Grid>
      ))}
    </aside>
  );
}

function Contact() {
  const classes = useStyles();
  return (
    <aside className={classes.aside}>
      <Typography variant="h3">
        <MailOutline/> Nous contacter
      </Typography>
      <ReactMarkdown source={footer.contact}/>
    </aside>
  );
}

function Links() {
  const classes = useStyles();
  return (
    <aside className={classes.aside}>
      <Typography variant="h3">
        <Link/> Aller plus loin
      </Typography>
      <ReactMarkdown source={footer.links} />
    </aside>
  );
}


export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}><Contact/></Grid>
          <Grid item xs={12} sm={6}><Links/></Grid>
        </Grid>
        <Grid container spacing={3} className={classes.topline}>
          <Grid item xs={12} sm={8}><Copyright /></Grid>
          <Grid item xs={12} sm={4}><SocialNetworks /></Grid>
        </Grid>
      </Container>
    </footer>
  );
}